body {
  color: #ffffff;
}

h1 {
  font-family: 'Oxanium', cursive;
  font-size: 4em;
  line-height: 1;
  margin-bottom: 0.2em;
  text-shadow: rgb(146, 123, 31) 1px 0 10px;
}

/*
 * Begin Vaporwave theme.
 * Credit: https://codepen.io/mtsgeneroso/pen/LYEWBMd
 */

$bg-color: #2b1165;
$fg-color: #f54171;

$bg-gradient: linear-gradient(
  180deg,
  $bg-color 0%,
  rgba(26, 58, 130, 1) 37%,
  rgba(171, 36, 177, 1) 69%,
  $fg-color 100%
);
$fg-gradient: linear-gradient(
  0deg,
  $bg-color 0%,
  rgba(26, 58, 130, 1) 37%,
  rgba(171, 36, 177, 1) 69%,
  $fg-color 100%
);

* {
  box-sizing: border-box;
}

body {
  background-color: $bg-color;
  background: $bg-gradient;

  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  position: relative;

  overflow: hidden;
}

.overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  background-size: 5px 5px, 5px 5px;
  background-position: -1px -1px, -1px -1px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  mix-blend-mode: difference;
  z-index: -1;
}

.glitch {
  clip-path: polygon(0 0, 100% 0, 100% 0.5em, 0 0.5em);
  animation: glitch 10s linear infinite;
  transform: translatex(0.1rem);
}

@keyframes glitch {
  to {
    clip-path: polygon(0 calc(100% - 0.5em), 100% calc(100% - 0.5em), 0 100%, 0 100%);
  }
}

.sun {
  width: 40vh;
  height: 40vh;
  font-size: 1rem;

  border-radius: 20vh;

  position: relative;
  overflow: hidden;
  &:before {
    content: '';

    display: block;
    position: absolute;
    top: 0;
    height: 50%;
    background-color: $fg-color;
    background: $fg-gradient;
    background-size: 40vh 40vh;
    width: 100%;
  }
  &:after {
    content: '';

    display: block;
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 50%;
    background-color: $fg-color;
    background: $fg-gradient;
    background-size: 40vh 40vh;
    background-position: bottom center;
    clip-path: polygon(
      0 -10em,
      100% -10em,
      100% -10.5em,
      0 -10.5em,
      0 -9em,
      100% -9em,
      100% -9.5em,
      0 -9.5em,
      0 -8em,
      100% -8em,
      100% -8.5em,
      0 -8.5em,
      0 -7em,
      100% -7em,
      100% -7.5em,
      0 -7.5em,
      0 -6em,
      100% -6em,
      100% -6.5em,
      0 -6.5em,
      0 -5em,
      100% -5em,
      100% -5.5em,
      0 -5.5em,
      0 -4em,
      100% -4em,
      100% -4.5em,
      0 -4.5em,
      0 -3em,
      100% -3em,
      100% -3.5em,
      0 -3.5em,
      0 -2em,
      100% -2em,
      100% -2.5em,
      0 -2.5em,
      0 -1em,
      100% -1em,
      100% -1.5em,
      0 -1.5em,
      0 0,
      100% 0,
      100% -0.5em,
      0 -0.5em,
      0 0,
      100% 0,
      100% 0.5em,
      0 0.5em,
      0 1em,
      100% 1em,
      100% 1.5em,
      0 1.5em,
      0 2em,
      100% 2em,
      100% 2.5em,
      0 2.5em,
      0 3em,
      100% 3em,
      100% 3.5em,
      0 3.5em,
      0 4em,
      100% 4em,
      100% 4.5em,
      0 4.5em,
      0 5em,
      100% 5em,
      100% 5.5em,
      0 5.5em,
      0 6em,
      100% 6em,
      100% 6.5em,
      0 6.5em,
      0 7em,
      100% 7em,
      100% 7.5em,
      0 7.5em,
      0 8em,
      100% 8em,
      100% 8.5em,
      0 8.5em,
      0 9em,
      100% 9em,
      100% 9.5em,
      0 9.5em
    );
    animation: lightEffect 20s linear infinite reverse;
  }
}

@keyframes lightEffect {
  to {
    clip-path: polygon(
      0 0,
      100% 0,
      100% 0.5em,
      0 0.5em,
      0 1em,
      100% 1em,
      100% 1.5em,
      0 1.5em,
      0 2em,
      100% 2em,
      100% 2.5em,
      0 2.5em,
      0 3em,
      100% 3em,
      100% 3.5em,
      0 3.5em,
      0 4em,
      100% 4em,
      100% 4.5em,
      0 4.5em,
      0 5em,
      100% 5em,
      100% 5.5em,
      0 5.5em,
      0 6em,
      100% 6em,
      100% 6.5em,
      0 6.5em,
      0 7em,
      100% 7em,
      100% 7.5em,
      0 7.5em,
      0 8em,
      100% 8em,
      100% 8.5em,
      0 8.5em,
      0 9em,
      100% 9em,
      100% 9.5em,
      0 9.5em,
      0 10em,
      100% 10em,
      100% 10em,
      0 10em,
      0 11em,
      100% 11em,
      100% 11.5em,
      0 11.5em,
      0 12em,
      100% 12em,
      100% 12.5em,
      0 12.5em,
      0 13em,
      100% 13em,
      100% 13.5em,
      0 13.5em,
      0 14em,
      100% 14em,
      100% 14.5em,
      0 14.5em,
      0 15em,
      100% 15em,
      100% 15.5em,
      0 15.5em,
      0 16em,
      100% 16em,
      100% 16.5em,
      0 16.5em,
      0 17em,
      100% 17em,
      100% 17.5em,
      0 17.5em,
      0 18em,
      100% 18em,
      100% 18.5em,
      0 18.5em,
      0 19em,
      100% 19em,
      100% 19.5em,
      0 19.5em,
      0 20em,
      100% 20em,
      100% 20.5em,
      0 20.5em
    );
  }
}
